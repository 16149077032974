package org.example.zone360.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseMove
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.translateY
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLElement

@Composable
fun AudioProgressSlider(
    progress: Float,
    onProgressChange: (Float) -> Unit,
    modifier: Modifier = Modifier
) {
    var isDragging by remember { mutableStateOf(false) }
    var startX by remember { mutableStateOf(0f) }
    var initialProgress by remember { mutableStateOf(progress) }

    Box(
        modifier = modifier
            .height(48.px)
            .fillMaxWidth()
            .onMouseDown { event ->
                isDragging = true
                startX = event.clientX.toFloat()
                initialProgress = progress

                // Allow immediate progress update on initial click
                val rect = event.currentTarget as? HTMLElement
                rect?.let {
                    val width = it.offsetWidth.toFloat()
                    val clickX = event.offsetX.toFloat()
                    val newProgress = (clickX / width).coerceIn(0f, 1f)
                    onProgressChange(newProgress)
                }
            }
            .onMouseMove { event ->
                if (isDragging) {
                    val rect = event.currentTarget as? HTMLElement
                    rect?.let {
                        val width = it.offsetWidth.toFloat()
                        val delta = event.clientX.toFloat() - startX
                        val progressDelta = delta / width
                        val newProgress = (initialProgress + progressDelta).coerceIn(0f, 1f)
                        onProgressChange(newProgress)
                    }
                }
            }
            .onMouseUp {
                isDragging = false
            }
            .onMouseLeave {
                isDragging = false
            }
    ) {
        // Background track
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(4.px)
                .backgroundColor(Color.lightgray)
                .align(Alignment.Center)
        )

        // Progress indicator
        Box(
            modifier = Modifier
                .height(4.px)
                .width((progress * 100).percent)
                .backgroundColor(Color.blue)
                .align(Alignment.CenterStart)
        )

        // Thumb
        Box(
            modifier = Modifier
                .size(16.px)
                .backgroundColor(Color.blue)
                .borderRadius(50.percent)
                .position(Position.Absolute)
                .left((progress * 100).percent)
                .top(50.percent)
                .translateY((-50).percent)
        )
    }
}


@Composable
fun AudioProgressSliderCustom(
    progress: Float, // Current audio progress as a percentage (0.0 to 1.0)
    onProgressChange: (Float) -> Unit, // Called when the user drags the slider
) {
    // Create a Slider UI element
    Input(
        type = InputType.Range,
        attrs = Modifier.height(2.px).toAttrs {
            attr("value", (progress * 100).toString()) // Multiply by 100 for percentage
            attr("min", "0")
            attr("max", "100")
            attr("step", "1")
            onInput {
                // Handle slider input change
                val newProgress = it.value?.toFloat()
                println("newProgress: $newProgress")
                if (newProgress != null) {
                    // Update the progress based on the slider position
                    onProgressChange(newProgress / 100) // Convert back to 0.0 - 1.0 range
                }
            }
        },
    )
}


