package org.example.zone360.pages.dashboard

import RecommendationPopup
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import getClubCoaches
import getClubKeyword
import getClubSession
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.models.GetClubKeywordResponse
import org.example.zone360.models.SessionData
import org.example.zone360.pages.dashboard.clubPerformance.ClubPerformance
import org.example.zone360.pages.dashboard.coaches.CoachesLayout
import org.example.zone360.pages.dashboard.emphasisBreakdown.EmphasisBreakdown
import org.example.zone360.pages.dashboard.infoLayout.InfoLayout
import org.example.zone360.pages.dashboard.keywordUsage.KeywordUsage
import org.example.zone360.pages.dashboard.recentHighlight.RecentHighlight
import org.example.zone360.sections.DashboardHeaderSection
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/home")
@Composable
fun DashboardPage() {
    val ctx = rememberPageContext()
    val scope = rememberCoroutineScope()
    val clubCoachesList = remember { mutableStateListOf<GetClubCoachesData>() }
    var keywordResponse by remember { mutableStateOf<GetClubKeywordResponse?>(null) }
    val clubSessionList = remember { mutableStateListOf<SessionData>() }
    var selectedCoach by remember { mutableStateOf(GetClubCoachesData()) }
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()
    var selectedLanguage by remember {
        mutableStateOf(
            when (localStorage.getItem("selectedLanguage")) {
                "it" -> SelectedLanguage.Italian
                else -> SelectedLanguage.English
            }
        )
    }
    var isRecommendationPopupVisible by remember { mutableStateOf(false) }
    var isLoading by remember { mutableStateOf(true) }
    var language by remember { mutableStateOf(SelectedLanguage.English.name) }

    val data = localStorage.getItem("userData")
    if (data == null) {
        ctx.router.navigateTo("/login")
        return
    }

    if (isRecommendationPopupVisible) {
        println("selectedCoach1: $selectedCoach")
        RecommendationPopup(
            onDialogDismiss = {
                isRecommendationPopupVisible = false
            },
            breakpoint = breakpoint,
            modalNumber = 1,
            data = selectedCoach,
            language = language

            )
    }
    suspend fun fetchData(language: String) {
        try {
            isLoading = true
            val user = getClubCoaches(AppGlobal.userData.organization, "soccer", language)
             // Update efficiency for each item
            user.data.forEach { item ->
                    val efficiency = if (item.speakingTime > 0) {
                        item.totalKeywords / item.speakingTime
                    } else {
                        0.0
                    }
                    item.efficiency = (efficiency * 100)
                }
            clubCoachesList.clear()
            clubCoachesList.addAll(user.data)
            println(AppGlobal.userData.accessToken)
//            println("mango: ${Json.encodeToString(clubCoachesList)}")

            keywordResponse = getClubKeyword(
                organization = AppGlobal.userData.organization,
                domainType = "soccer",
                language = language,
                keyword = "sprint",
                getSentences = false
            )
            val sessionUser = getClubSession(AppGlobal.userData.organization, "soccer", language)
            clubSessionList.clear()
            clubSessionList.addAll(sessionUser.data)
            println("clubSessionList: $clubSessionList")
        } catch (e: Exception) {
            println("Error fetching data: $e")
        } finally {
            isLoading = false
        }
    }

    LaunchedEffect(selectedLanguage) {
        val languageCode = when (selectedLanguage) {
            SelectedLanguage.English -> "en"
            SelectedLanguage.Italian -> "it"
        }
        language=languageCode

        localStorage.setItem("selectedLanguage", languageCode)
        println(AppGlobal.userData.accessToken)
        println("selectedLanguage: $selectedLanguage")
        fetchData(languageCode)
    }


    Box(Modifier.fillMaxSize()) {
        Column(
            Modifier.fillMaxSize().backgroundColor(sitePalette.bodyBackground.rgb)
                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto)
                .id("dashboardPage")
        ) {
            DashboardHeaderSection()

            Column(

                modifier = Modifier.padding(leftRight = if (breakpoint >= Breakpoint.LG) 60.px else 20.px)
                    .fillMaxSize()
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().margin(top = 10.px, bottom = 10.px)
                        .height(36.px),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    SpanText(
                        "Dashboard", modifier = Modifier
                            .fontFamily(Constants.FONT_FAMILY)
                            .fontWeight(FontWeight.Bold)
                            .fontSize(if (breakpoint >= breakpoint) 20.px else 15.px)
                            .margin(right = 10.px)
                    )
                    Spacer()
//                    SelectedBox(
//                        text = "English",
//                        breakpoint = breakpoint,
//                        isSelected = selectedLanguage == SelectedLanguage.English,
//                        onClick = { selectedLanguage = SelectedLanguage.English
//                        }
//                    )
//                    SelectedBox(
//                        text = "Italian",
//                        breakpoint = breakpoint,
//                        isSelected = selectedLanguage == SelectedLanguage.Italian,
//                        onClick = { selectedLanguage = SelectedLanguage.Italian }
//                    )
                }
                InfoLayout(clubCoachesList, breakpoint)
                Row(
                    modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap)
                        .margin(topBottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {


                    if (isLoading) {
                        Column(
                            modifier = Modifier
                                .width(
                                    if (breakpoint > Breakpoint.LG) 68.percent else if (breakpoint == Breakpoint.LG) 59.percent
                                    else if (breakpoint >= Breakpoint.MD) 54.percent else 100.percent
                                )
                                .height(425.px)
                                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                                .borderRadius(15.px)
                                .overflow(
                                    overflowY = Overflow.Hidden,
                                    overflowX = if (breakpoint <= Breakpoint.LG) Overflow.Auto else Overflow.Hidden
                                )
                                .padding(topBottom = 15.px, leftRight = 50.px)

                            ){}
                            Box(
                                modifier = Modifier
                                    .height(if (window.screen.width < 765) 260.px else 425.px)
                                    .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
                                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                                    .width(
                                        if (breakpoint > Breakpoint.LG) 30.percent
                                        else if (breakpoint >= Breakpoint.LG) 39.percent
                                        else if (breakpoint >= Breakpoint.MD) 44.percent
                                        else 100.percent
                                    )
                                    .borderRadius(15.px)
                                    .flexWrap(FlexWrap.Wrap)
                                    .padding(if (breakpoint >= Breakpoint.LG) 40.px else 20.px)
                            )
                        }else{
                            keywordResponse?.let { response ->
                                ClubPerformance( response, breakpoint, selectedLanguage)
                                 EmphasisBreakdown(response, selectedLanguage, breakpoint)}
                    }
                }
                Row(
                    modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap)
                        .margin(topBottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {

                    if (isLoading){
                        Column (modifier = Modifier
                            .fillMaxWidth()
                            .height(209.px)
                            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                            .borderRadius(15.px)
                            .padding(topBottom = 10.px)
                        ){}
                    }
                    else
                    keywordResponse?.let { response ->
                        KeywordUsage(response, selectedLanguage)
                    }
                }
                Row(
                    modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                        .flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    CoachesLayout(clubCoachesList, breakpoint, onRecommendationClick = { data ->
                        selectedCoach = data
                        isRecommendationPopupVisible = true
                        println("selectedCoach: $selectedCoach")
                    })
                    RecentHighlight(clubSessionList, breakpoint)
                }
            }
        }
    }
}

@Composable
fun SelectedBox(
    text: String,
    breakpoint: Breakpoint,
    isSelected: Boolean,
    onClick: () -> Unit
) {
    val sitePalette = ColorMode.current.toSitePalette()
    Box(
        modifier = Modifier
            .height(32.px)
//            .width(41.px)
            .backgroundColor(
                if (isSelected) sitePalette.primary.rgb
                else sitePalette.modalBackgroundColor.rgb
            )
            .borderRadius(5.px)
            .onClick { onClick() }
            .cursor(Cursor.Pointer)
            .padding( 10.px)
            .margin(right = 12.px),
        contentAlignment = Alignment.Center
    ) {
        SpanText(
            text,
            modifier = Modifier
                .fontFamily(Constants.FONT_FAMILY)
                .color(
                    if (isSelected) sitePalette.modalBackgroundColor.rgb
                    else sitePalette.textFieldSupportiveTextColor.rgb
                )
                .fontSize(if (breakpoint >= Breakpoint.LG) 13.px else 11.px)
        )
    }
}

@Composable
fun SectionTitle(
    text: String, bottom: CSSSizeValue<CSSUnit.px> = 15.px,
    top: CSSSizeValue<CSSUnit.px> = 0.px,
    right: CSSSizeValue<CSSUnit.px> = 0.px,
    left: CSSSizeValue<CSSUnit.px> = 0.px,
) {
    SpanText(
        text, modifier = Modifier
            .fontFamily(Constants.FONT_FAMILY)
            .fontWeight(600)
            .fontSize(20.px)
            .margin(bottom = bottom, top = top, right = right, left = left)
    )
}
