package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetClubKeywordResponse(
    val data: List<GetClubKeywordData>,
)
@Serializable
data class GetClubKeywordData(
    val key: List<String> ? = null,
    val value: Value ? = null,
)
@Serializable
data class Value(
    val count: Int=0,
    val keywordCategory: String? = null,
    @SerialName("sessionID")
    val sessionId: String? = null,
    val text: String? = null
)
