package org.example.zone360.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.example.zone360.theme.Palette

val DropdownStyleLight by ComponentStyle {
    hover {
        Modifier.backgroundColor(Palette.EditTextBorder.rgbLight)
    }
}
val DropdownStyleDark by ComponentStyle {
    hover {
        Modifier.backgroundColor(Palette.Primary.rgbDark)
    }
}