package org.example.zone360.pages.dashboard.chart

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.browser.window
import org.example.zone360.models.GetClubKeywordResponse
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Canvas
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLCanvasElement


@Composable
fun ChartPage(
    breakpoint: Breakpoint,
    data: GetClubKeywordResponse?,
    selectedLanguage: SelectedLanguage,
) {
    var xValues by remember { mutableStateOf(listOf("Technical", "Fitness", "Tactical", "Mental", "Goalkeeper")) }
    var yValues by remember { mutableStateOf(listOf(0, 0, 0, 0, 0)) }
    var barColors by remember { mutableStateOf(emptyList<String>()) }
    val sitePalette = ColorMode.current.toSitePalette()

    // Default bar colors for each category
    barColors = listOf(
        sitePalette.technicalCategoryBorder.hex,
        sitePalette.fitnessCategoryBorder.hex,
        sitePalette.tacticalCategoryBorder.hex,
        sitePalette.mentalCategoryBorder.hex,
        sitePalette.goalKeeperCategoryBorder.hex
    )

    // Process actual data
    LaunchedEffect(data) {
        if (data != null && data.data.isNotEmpty()) {
            val groupedData = data.data
                .filter { it.value?.keywordCategory != null }
                .groupBy { it.value?.keywordCategory }
                .mapValues { entry -> entry.value.sumOf { it.value?.count ?: 0 } }

            xValues = groupedData.keys.toList() as List<String>
            yValues = groupedData.values.toList()

            barColors = xValues.map {
                when (it.lowercase()) {
                    if (selectedLanguage == SelectedLanguage.English) "technical" else "tecnica" -> sitePalette.technicalCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "fitness" else "atletico" -> sitePalette.fitnessCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "tactical" else "tattica" -> sitePalette.tacticalCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "mental" else "mentale" -> sitePalette.mentalCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "goalkeeper" else "portiere" -> sitePalette.goalKeeperCategoryBorder.hex
                    else -> "#000000" // Default color for unknown categories
                }
            }
        }
    }

    val chartWidth = if (window.screen.width < 1505) 160 else 200
    val chartHeight = if (window.screen.width < 1505) 160 else 200

    Canvas(
        Modifier
            .width(chartWidth.px)
            .height(chartHeight.px)
            .id("myChart")
            .toAttrs()
    ) {}

    LaunchedEffect(Unit) {
        val canvas = document.getElementById("myChart") as? HTMLCanvasElement
        if (canvas != null) {
            // Fallback: Ensure at least one non-zero value
            val adjustedYValues = if (yValues.all { it == 0 }) yValues.map { 1 } else yValues

            val script = """
            (function() {
                const xValues = ${xValues.map { "\"$it\"" }};
                const yValues = ${adjustedYValues};
                const barColors = ${barColors.map { "\"$it\"" }};
                const total = ${yValues.sum()}; // Sum the actual yValues

                new Chart("myChart", {
                    type: "doughnut",
                    data: {
                        labels: xValues,
                        datasets: [{
                            backgroundColor: barColors,
                            data: yValues
                        }]
                    },
                    options: {
                        legend: { display: false },
                        title: { display: false },
                        cutoutPercentage: 75,
                        responsive: false,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    const actualValue = ${yValues}[tooltipItem.index]; // Use the actual yValues
                                    const percentage = ((actualValue / (total || 1)) * 100).toFixed(2);
                                    const label = data.labels[tooltipItem.index];
                                    return label + ": " + percentage + "%";
                                }
                            }
                        }
                    }
                });
            })();
        """.trimIndent()

            val scriptElement = document.createElement("script")
            scriptElement.textContent = script
            document.body?.appendChild(scriptElement)
        } else {
            console.error("Canvas element not found")
        }
    }

}





