package org.example.zone360.style

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import org.example.zone360.theme.Palette
import org.jetbrains.compose.web.css.ms

val CategoryItemStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }
    anyLink {
        Modifier.color(Colors.White)
    }
    hover {
        com.varabyte.kobweb.compose.ui.Modifier.color(Palette.ParrotGreen.rgbLight)
    }
}
val CategoryItemStyleDark by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }
    anyLink {
        Modifier.color(Colors.White)
    }
    hover {
        com.varabyte.kobweb.compose.ui.Modifier.color(Palette.Primary.rgbDark)
    }
}