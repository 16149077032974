package org.example.zone360.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaSortDown
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.localStorage
import org.example.zone360.components.DropdownItem
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants.DASHBOARD_HEADER_HEIGHT
import org.example.zone360.utils.Constants.FONT_FAMILY
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb

@Composable
fun DashboardHeaderSection() {
    val sitePalette = ColorMode.current.toSitePalette()
    val headerHeight by remember { mutableStateOf(DASHBOARD_HEADER_HEIGHT) }
    Box(
        modifier = Modifier.fillMaxSize().backgroundColor(sitePalette.headerBackgroundColor.rgb)
            .boxShadow(color = sitePalette.modalBackgroundColor.rgb, offsetX = 2.px, offsetY = 2.px)
            .maxHeight(headerHeight.px),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier.fillMaxSize().backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .fillMaxWidth()
                .padding(leftRight = 60.px, topBottom = 3.px),
            contentAlignment = Alignment.Center
        ) {
           DashboardHeader()

        }
    }
}

@Composable
fun DashboardHeader() {
    val ctx = rememberPageContext()
    val sitePalette = ColorMode.current.toSitePalette()
    var isDropdownOpen by remember { mutableStateOf(false) }

    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(src = Res.Images.logosvg, modifier = Modifier.color(sitePalette.primary.rgb).size(140.px))
        Spacer()
        if (AppGlobal.userData.imageUrl.isNotEmpty()) {
            Image(
                src = "${Res.Images.coachImageUrl}${AppGlobal.userData.imageUrl}",
                modifier = Modifier.size(30.px).margin(right = 8.px)
                    .borderRadius(100.px).textAlign(TextAlign.Start)
                    .border (width = 2.px, color = sitePalette.primary.rgb, style = LineStyle.Solid)
            )
        } else {
            Image(
                src = Res.Images.userIcon,
                modifier = Modifier.size(25.px).margin(right = 7.px)
                    .borderRadius(100.px)
            )
        }
        SpanText("${AppGlobal.userData.firstName} ${AppGlobal.userData.lastName}",
            modifier = Modifier.color(sitePalette.textFieldTextColor.rgb).fontFamily(FONT_FAMILY).margin(right = 8.px))
        Box(
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .onClick { isDropdownOpen = !isDropdownOpen },

        ) {
            Column(horizontalAlignment = Alignment.CenterHorizontally) {

            FaSortDown()
            if (isDropdownOpen) {
                Box(
                    modifier = Modifier
                        .position(Position.Absolute)
                        .top(20.px)
                        .margin(top = 50.px)
                        .backgroundColor(rgb(255, 255, 255))
                        .border(1.px, LineStyle.Solid, rgb(200, 200, 200))
                        .zIndex(1)
                        .padding(8.px)
                ) {
                    DropdownItem("Logout") {
                        localStorage.clear()
                        ctx.router.navigateTo("/login")
                        isDropdownOpen = false
                    }
                }
            }
            }

        }
        }

        }