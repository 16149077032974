package org.example.zone360.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.silk.components.animation.Keyframes
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.px

@OptIn(ExperimentalComposeWebApi::class)
val Spin by Keyframes {
    from { Modifier.transform { rotate(0.deg) } }
    to { Modifier.transform { rotate(360.deg) } }
}

@OptIn(ExperimentalComposeWebApi::class)
val ShiftRight by Keyframes {
    from { Modifier.left(0.px) }
    to { Modifier.left(200.px) }
}
