package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.example.zone360.utils.SelectedLanguage

@Serializable
data class UserData(
    @SerialName("_id")
    val id: String = "",
    @SerialName("_rev")
    val rev: String = "",
    val accessToken: String = "",
    val email: String = "",
    val firstName: String = "",
    val language: String = "en",
    val imageUrl: String = "",
    val lastName: String = "",
    val organization: String = "",
    val role: String = "",
    val status: String = "",
    val type: String = "",
    val uid: String = "",
)
