package org.example.zone360.style


import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.example.zone360.theme.Palette
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val LoginInputStyleDefault = Modifier
    .border(
        width = 1.px,
        style = LineStyle.Solid,
        color = Palette.EditTextBorder.rgbLight
    ).fontFamily(Constants.FONT_FAMILY)

    .transition(CSSTransition(property = "border", duration = 300.ms))

val LoginInputStyleError = Modifier.border(
    width = 1.px,
    style = LineStyle.Solid,
    color = Palette.Error.rgbLight
)


val LoginInputStyleFocus = Modifier.border(
    width = 1.px,
    style = LineStyle.Solid,
    color = Colors.Green
)


val InputStyleDefault by ComponentStyle {

    val modifier = Modifier
        .border(
            width = 1.px,
            style = LineStyle.Solid,
            color = Palette.EditTextBorder.rgbLight
        ).fontFamily(Constants.FONT_FAMILY)

        .transition(CSSTransition(property = "border", duration = 300.ms))
        .styleModifier {
            property("-webkit-appearance", "none")
            property("inputmode", "numeric")

        }.margin(0.px)


    cssRule("::-webkit-outer-spin-button") {
        modifier
    }
    cssRule("::-webkit-inner-spin-button") {
        modifier
    }
}

val InputStyleFocus by ComponentStyle {

    val modifier = Modifier
        .border(
            width = 1.px,
            style = LineStyle.Solid,
            color = Colors.Green
        ).fontFamily(Constants.FONT_FAMILY)

        .transition(CSSTransition(property = "border", duration = 300.ms))
        .styleModifier {
            property("-webkit-appearance", "none")
            property("inputmode", "numeric")

        }.margin(0.px)

    cssRule("::-webkit-outer-spin-button") {
        modifier
    }
    cssRule("::-webkit-inner-spin-button") {
        modifier
    }
}


val InputStyleError by ComponentStyle {

    val modifier = Modifier
        .border(
            width = 1.px,
            style = LineStyle.Solid,
            color = Palette.Error.rgbLight
        ).fontFamily(Constants.FONT_FAMILY)

        .transition(CSSTransition(property = "border", duration = 300.ms))
        .styleModifier {
            property("-webkit-appearance", "none")
            property("inputmode", "numeric")

        }.margin(0.px)

    cssRule("::-webkit-outer-spin-button") {
        modifier
    }
    cssRule("::-webkit-inner-spin-button") {
        modifier
    }
}


// Text Area

val TextAreaStyle = Modifier.border(
    width = 1.px,
    style = LineStyle.Solid,
    color = Palette.EditTextBorder.rgbLight
).transition(CSSTransition(property = "border", duration = 300.ms))

val TextAreaStyleFocus = Modifier.border(
    width = 1.px,
    style = LineStyle.Solid,
    color = Colors.Green
).transition(CSSTransition(property = "border", duration = 300.ms))

