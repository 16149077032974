package org.example.zone360.pages.dashboard.recentHighlight

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaDownload
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.example.zone360.components.AudioProgressSliderCustom
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.components.formatSessionDate
import org.example.zone360.components.formatSessionHours
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.models.SessionData
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.Audio
import org.w3c.dom.HTMLAnchorElement

@Composable
fun RecentHighlight(
    clubSessionList: List<SessionData>,
    breakpoint: Breakpoint
    ){
    val sitePalette = ColorMode.current.toSitePalette()
    Box (modifier = Modifier
        .then(
            if ( window.screen.width > 765) Modifier.height(915.px)
            else Modifier.maxHeight(915.px)
        )
        .margin(topBottom = if (breakpoint <= Breakpoint.MD)10.px else 0.px)
        .width(
            if (breakpoint > Breakpoint.LG) 30.percent
            else if (breakpoint >= Breakpoint.LG) 39.percent
            else if (breakpoint >= Breakpoint.MD) 44.percent
            else 100.percent
        )
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .borderRadius(15.px)
        .padding( 20.px)
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            SectionTitle("Recent Sessions")
            Column(modifier = Modifier.fillMaxWidth()
                .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)) {
                if (clubSessionList.isNotEmpty()) {
               SessionItem(clubSessionList)
            }
            }
        }
    }
}

@Composable
fun SessionItem(
    data: List<SessionData>
) {
   val sitePalette = ColorMode.current.toSitePalette()
    var currentlyPlayingId by remember { mutableStateOf<String?>(null) }
    var loadingSessionId by remember { mutableStateOf<String?>(null) }
    val currentAudioRef = remember { mutableStateOf<Audio?>(null) }

    data.forEach { sessionData ->
        val sessionId = sessionData.sessionId
        val parts = sessionData.sessionId.removeSuffix(".aac").split("_")
        val sessionName = parts.first()
        val timeStamp = parts.last()
        val audio = remember { Audio("${Res.Audio.recording_Audio}${sessionData.sessionId}") }

        var audioProgress by remember { mutableStateOf(0f) } // Progress in percentage (0 to 1)
        val downloadAudio: () -> Unit = {
            // Construct the full audio URL
            val audioUrl = "${Res.Audio.recording_Audio}${sessionData.sessionId}"

            // Create a temporary anchor element to trigger download
            val downloadLink = document.createElement("a") as HTMLAnchorElement
            downloadLink.href = audioUrl
            downloadLink.download = "${sessionName}_${timeStamp}.aac"

            // Append to body, click, and remove
            document.body?.appendChild(downloadLink)
            downloadLink.click()
            document.body?.removeChild(downloadLink)
        }
        DisposableEffect(Unit) {
            onDispose {
                if (currentlyPlayingId == sessionData.sessionId) {
                    audio.pause()
                    currentlyPlayingId = null
                    audioProgress = 0f
                }
            }
        }

        LaunchedEffect(currentlyPlayingId) {
            if (currentlyPlayingId != null) {
                val audioPlay = currentAudioRef.value
                audioPlay?.apply {
                    while (currentTime < duration) {
                        audioProgress = (currentTime / duration).toFloat()
                        delay(100)
                    }
                }
            }
        }

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(5.px)
        ) {
            SpanText(
                if(sessionData.sessionType == "player assessment"){
                    "${sessionData.playerName}'s assessment"
                } else sessionName,
                modifier = Modifier
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontWeight(500)
                    .fontSize(16.px)
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                SpanText(
                    sessionData.coachName,
                    modifier = Modifier
                        .fontFamily(Constants.FONT_FAMILY)
                        .width(110.px)
                        .fontWeight(400)
                        .fontSize(13.px)
                )

                Row(modifier = Modifier.width(100.px),
                    verticalAlignment = Alignment.CenterVertically) {
                    Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                    CustomSpacer(5.px)
                    SpanText(
                        sessionData.recordTime.toLongOrNull()?.let {
                            (it / 1000).formatSessionMinutes()
                        } ?: "Invalid Time",
                        modifier = Modifier
                            .fontFamily(Constants.FONT_FAMILY)
                            .fontWeight(400)
                            .margin(right = 3.px)
                            .fontSize(13.px)
                    )
                }

                Row(modifier = Modifier.width(150.px),
                    verticalAlignment = Alignment.CenterVertically) {
                    Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                    CustomSpacer(5.px)
                    SpanText(
                        "${timeStamp.formatSessionDate()} ${timeStamp.formatSessionHours()}",
                        modifier = Modifier
                            .fontFamily(Constants.FONT_FAMILY)
                            .fontWeight(400)
                            .fontSize(13.px)
                    )
                }
 Row (verticalAlignment = Alignment.CenterVertically){
                if (loadingSessionId == sessionData.sessionId) {
                    Box(contentAlignment = Alignment.Center) {
                        Image(
                            src = Res.Images.blueCircle,
                            modifier = Modifier.size(30.px).borderRadius(100.px)
                        )
                        ProgressIndicator(10.px)
                    }
                } else if (currentlyPlayingId == sessionData.sessionId) {
                    Image(
                        src = Res.Images.pausebutton,
                        modifier = Modifier.size(30.px)
                            .cursor(Cursor.Pointer)
                            .onClick {
                                audio.pause()
                                currentlyPlayingId = null
                                currentAudioRef.value = null
                                audioProgress = 0f
                            }
                    )
                } else {
                    Image(
                        src = Res.Images.playbutton,
                        modifier = Modifier.size(30.px)
                            .cursor(Cursor.Pointer)
                            .onClick {

                                currentAudioRef.value?.pause()
                                loadingSessionId = sessionId

                                audio.onloadeddata = {
                                    loadingSessionId = null
                                    audio.play()
                                    currentlyPlayingId = sessionId
                                    audio.also { currentAudioRef.value = it }
                                }
                                audio.load()
                            }
                    )
                }
     CustomSpacer(6.px)
                FaDownload(modifier = Modifier.color(sitePalette.primary.rgb)
                    .cursor(Cursor.Pointer)
                    .onClick { downloadAudio() })}
            }

            if (currentlyPlayingId == sessionData.sessionId) {
                Row (modifier = Modifier.fillMaxWidth().margin(topBottom = 4.px),
                    horizontalArrangement = Arrangement.End){
                    AudioProgressSliderCustom(
                        progress = audioProgress,
                        onProgressChange = { newProgress ->
                            audioProgress = newProgress
                            currentAudioRef.value?.currentTime = (currentAudioRef.value?.duration ?: 0.0) * newProgress
                        },
                    )
                }
            }
            HorizontalDivider(modifier = Modifier.color(Colors.Gray).fillMaxWidth())
        }
    }
}




