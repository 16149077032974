package org.example.zone360.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import kotlinx.browser.localStorage
import org.example.zone360.pages.authentication.LoginPage
import org.example.zone360.pages.dashboard.DashboardPage

@Page
@Composable
fun HomePage() {

    val data = localStorage.getItem("userData")
    if (data != null) DashboardPage() else LoginPage()
}


