package org.example.zone360.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.checked
import org.example.zone360.theme.Palette

val CheckboxStyle by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("border-color", Palette.EditTextBorder.hexLight)
        }
    }
    checked {
        Modifier.styleModifier {
            property("background-color", Palette.Primary.hexLight)
            property("border-color", Palette.Primary.hexLight)
        }
    }
}
val CheckboxStyleDark by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("border-color", Palette.EditTextBorder.hexDark)
        }
    }
    checked {
        Modifier.styleModifier {
            property("background-color", Palette.Primary.hexDark)
            property("border-color", Palette.Primary.hexDark)
        }
    }
}

val CheckboxStyleError by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("border-color", Palette.Error.hexLight)
        }
    }
    checked {
        Modifier.styleModifier {
            property("background-color", Palette.Error.hexLight)
            property("border-color", Palette.Error.hexLight)
        }
    }
}

//
val RadioStyle by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("border-color", Palette.EditTextBorder.hexLight)
            property("background-color", Palette.Modal.hexLight)

        }
    }
    checked {
        Modifier.styleModifier {
            property("background-color", Palette.Primary.hexLight)
            property("border-color", Palette.Primary.hexLight)
        }
    }
}
val RadioStyleDark by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("border-color", Palette.EditTextBorder.hexDark)
            property("background-color", Palette.Modal.hexDark)

        }
    }
    checked {
        Modifier.styleModifier {
            property("background-color", Palette.Primary.hexDark)
            property("border-color", Palette.Primary.hexDark)
        }
    }
}