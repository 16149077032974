@file:Suppress("UNREACHABLE_CODE")

import com.varabyte.kobweb.browser.http.http
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.example.zone360.models.GetClubCoachesResponse
import org.example.zone360.models.GetClubKeywordResponse
import org.example.zone360.models.GetSessionSummaryResponse
import org.example.zone360.models.SessionResponse
import org.example.zone360.models.UserData

enum class APIMETHODS { GET, POST }
var accessToken: String? = null


fun saveAccessToken(token: String) {
    window.localStorage.setItem("accessToken", token)
}

fun loadAccessToken(): String? {
    return window.localStorage.getItem("accessToken")?.takeIf { it.isNotEmpty() }
}


suspend fun httpRequest(
    url: String,
    method: APIMETHODS,
    body: ByteArray? = null,
    headers: Map<String, String>? = null
): String? {
    return try {
        val response = if (method == APIMETHODS.GET) {
            window.http.tryGet(url.trim(), headers = headers)
        } else {
            window.http.tryPost(url.trim(), body = body, headers = headers)
        }
        if (response == null) throw IllegalStateException("Response is null")

        val responseString = response.decodeToString()
        if (responseString.contains("\"status\":401")) {
            redirectToLogin()
            null
        } else {
            responseString
        }
    } catch (e: Exception) {
        console.error("HTTP Request failed: ${e.message}")
        null
    }
}

fun redirectToLogin() {
    console.log("Redirecting to login page due to 401 Unauthorized.")
    window.location.href = "/login"
}

fun getHeaders(): Map<String, String> {
    return loadAccessToken()?.let {
        mapOf("Authorization" to "Bearer $it")
    } ?: emptyMap()
}


suspend fun login(email: String, password: String): UserData {
    val jsonData = """{
        "email": "$email",
        "password": "$password"
    }"""

    val response: UserData = httpRequest(
        APIEndPoints.Name.login,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray()
    ).parseData()

    accessToken = response.accessToken
    // Save the token to localStorage
    accessToken?.let { saveAccessToken(it) }

    return response
}

suspend fun getClubSession(organization: String, domainType: String, language: String): SessionResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "language": "$language"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_session,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getClubKeyword(organization: String, domainType: String, language: String, keyword: String, getSentences: Boolean): GetClubKeywordResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "language": "$language",
        "keyword": "$keyword",
        "getSentences": $getSentences
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_Keyword,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
    println("HTTP Response: $jsonData")
}

suspend fun getClubCoaches(organization: String, domainType: String, language: String): GetClubCoachesResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "language": "$language"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_Coaches,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getSessionSummary(userID: String, domainType: String, language: String, getAll: Boolean): GetSessionSummaryResponse {
    val jsonData = """{
        "userID": "$userID",
        "domainType": "$domainType",
        "language": "$language",
        "getAll": $getAll
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Session_Summary,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
    println("HTTP Response: $jsonData")
}


object JsonConfig {
    val default: Json = Json {
        ignoreUnknownKeys = true
    }
}


inline fun <reified T> String?.parseData(): T {
    if (this == null) throw IllegalArgumentException("Response is null")
    return JsonConfig.default.decodeFromString(this)
}
