package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetSessionSummaryResponse(
    val data: List<GetSessionSummaryData>,
)
@Serializable

data class GetSessionSummaryData(
    val playerName: String,
    @SerialName("sessionID")
    val sessionId: String,
    val sessionType: String,
    val summary: String,
)
