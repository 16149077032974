package org.example.zone360.utils

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import kotlinx.browser.localStorage
import org.example.zone360.utils.Constants.EMAIL_REGEX
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import kotlin.math.roundToInt

fun isValidEmail(email: String): Boolean {
    return email.matches(EMAIL_REGEX.toRegex())
}

fun Modifier.noBorder(): Modifier {
    return this.border(
        width = 0.px,
        style = LineStyle.None,
        color = Colors.Transparent
    ).outline(
        width = 0.px,
        style = LineStyle.None,
        color = Colors.Transparent
    )
}


//
fun saveToLocalStorage(key: String, value: Any) {
    localStorage.setItem(key, value.toString())
}


fun getFromLocalStorage(key: String): String {
    return localStorage.getItem(key) ?: ""
}

//fun getTimeDifference(milliseconds: Long): String {
//
//    val nowUnixtime = Clock.System.now().toEpochMilliseconds()
//
//    val diff = nowUnixtime.minus(milliseconds)
//
//    val days = diff.milliseconds.inWholeDays
//    val hours = diff.milliseconds.inWholeHours
//    val minutes = diff.milliseconds.inWholeMinutes
//    val seconds = diff.milliseconds.inWholeSeconds
//
//
//
//    println("$days day(s) $hours hour(s) $minutes minute(s) $seconds second(s).");
//    if (days == 0L) {
//        if (hours == 0L) {
//            return if (minutes == 0L) {
//                "$seconds seconds(s) ago"
//            } else {
//                "$minutes minute(s) ago"
//            }
//        } else {
//            if (hours < 24) {
//                return "$hours hour(s) ago"
//            }
//        }
//    } else {
//        return "$days day(s) ago"
//    }
//
//
//    return days.toString()
//}

//fun convertMilliSecondsToDateTime(milliseconds: Long): String {
//
//
//    // define once somewhere in order to reuse it
//    val formatter = DateTimeFormatter.ofPattern("yyyy-mm-dd")
//    val instant = Instant.fromEpochMilliseconds(milliseconds)
//
//    val date = instant.toLocalDateTime(TimeZone.currentSystemDefault())
//
//    println("${date.year}-${date.monthNumber}-${date.dayOfMonth} ${date.time.hour}:${date.time.minute}:${date.time.minute}") // 10/12/2019 06:35:45
//
//
//    return "${date.year}-${date.monthNumber}-${date.dayOfMonth} ${date.time.hour}:${date.time.minute}:${date.time.second}"
//
//
//}
//
//fun getCurrentMilliseconds(): Long {
//    return Clock.System.now().toEpochMilliseconds()
//}

//
//fun setUserData(user: UserData? = null) {
//    println("user: $user")
//    if (user == null) {
//        if (getFromLocalStorage(USER_KEY).isNotEmpty()) {
//            AppGlobal.userData = Json.decodeFromString(getFromLocalStorage(USER_KEY))
//            println("userParsed: ${AppGlobal.userData.org.example.zone360.utils.login}")
//        }
//
//
//    } else {
//        AppGlobal.userData = user
//
//    }
//}

@Composable
fun roundToTwoDecimalPlaces(value: Double? = 0.0): Double {
    return (value!! * 100.0).roundToInt() / 100.0
}

fun roundToTwoDecimal(value: Double? = 0.0): Double {
    return (value!! * 100.0).roundToInt() / 100.0
}

//@Composable
//fun Toast(message: String, isError: Boolean) {
//    BSToastGroup {
//        BSToastBasic(
//            id = "toast",
//            text = message,
//            style = if (isError) ToastStyle.Danger else ToastStyle.Success,
//            autoHide = true,
//            closeButtonDark = false,
//            onCloseClick = {}
//        )
//    }
//}

@Composable
fun Divider() {
    HorizontalDivider(
        modifier = Modifier.height(1.px).fillMaxWidth().padding (all = 0.px).margin(all = 0.px)
            .backgroundColor(Colors.AliceBlue)
    )
}
@Composable
fun FooterHorizontalDivider(color: Color.Rgb, width: CSSLengthOrPercentageNumericValue) {
    HorizontalDivider(
        modifier = Modifier.height(2.px).width(width).padding (all = 0.px).margin(all = 0.px)
            .backgroundColor(color)
    )
}

//fun logout(context: PageContext) {
//    localStorage.clear()
//    window.document.cookie = "Authentication=null"
//    Config.autoUpdateEnabled = false
//    context.router.navigateTo(Screen.Login.route)
//}
//
////fun getLogo(variant: String): String {
////    return when (variant.lowercase()) {
////        "default" -> Res.Images.logoDefault
////        "green" -> Res.Images.logoGreen
////        "purple" -> Res.Images.logoPurple
////        "black" -> Res.Images.logoBlack
////        else -> {
////            Res.Images.logoDefault
////
////        }
////    }
////}
//
//fun getFieldColor(value: Double, sitePalette: SitePalette): Color {
//    val color = if (value < 0) {
//        sitePalette.textRedColor.rgb
//    } else {
//        sitePalette.textGreenColor.rgb
//    }
//
//    return color
//}

