package org.example.zone360.pages.dashboard.chart

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.serialization.Serializable
import org.example.zone360.models.GetClubKeywordResponse
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Canvas
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLCanvasElement

@Composable
fun LineChartPage(
    breakpoint: Breakpoint,
    data: GetClubKeywordResponse,
    selectedLanguage: SelectedLanguage
) {
    var datasets by remember { mutableStateOf(emptyList<Pair<List<Int>, String>>()) }
    var isDataReady by remember { mutableStateOf(false) }
    val sitePalette = ColorMode.current.toSitePalette()

    // Static xValues1 for the x-axis labels
    val xValues1 = listOf(
        "Week1",
        "Week2",
        "Week3",
        "Week4",
        "Week5",
    )

    // Sample initial datasets for testing
    val datasets34 = listOf(
        listOf(2) to "red",
        listOf(5) to "green",
        listOf(15) to "blue"
    )

    println("datasets34: $datasets34")

    LaunchedEffect(data) {
        if (data.data.isNotEmpty()) {
            // Extract only the defined data series
            val definedData = data.data

            val categoryProgressions1 = definedData
                .groupBy { it.value?.keywordCategory }
                .mapValues { (_, entries) ->
                    entries.sumOf { it.value?.count ?: 0 }
                }

            println("categoryProgressions: $categoryProgressions1")

            // Prepare datasetFinal with category names and their corresponding values
            val datasetFinal = categoryProgressions1.map { (category, totalValue) ->
                val color = when (category?.lowercase()) {
                    if (selectedLanguage == SelectedLanguage.English) "technical" else "Tecnica" -> sitePalette.technicalCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "fitness" else "atletico" -> sitePalette.fitnessCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "tactical" else "tattica" -> sitePalette.tacticalCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "mental" else "mentale" -> sitePalette.mentalCategoryBorder.hex
                    if (selectedLanguage == SelectedLanguage.English) "goalkeeper" else "portiere" -> sitePalette.goalKeeperCategoryBorder.hex
                    else -> "#000000"
                }

                // Create a dataset with a single-element list containing the total value
                listOf(totalValue) to color
            }

            datasets = datasetFinal
            println("dataset: $datasetFinal")

            isDataReady = true
        }
    }

//    if (isDataReady) {
        val chartWidth = 800

        Canvas(
            Modifier.fillMaxWidth()
                .height(350.px)
                .id("lineChart")
                .toAttrs()
        ) {}

        LaunchedEffect(Unit) {
            val canvas = document.getElementById("lineChart") as? HTMLCanvasElement
            if (canvas != null) {
                // Constructing dataset arrays for the chart
                val dataArrays = datasets.joinToString(",\n") { dataset ->
                    """
                    {
                        data: ${dataset.first}, 
                        borderColor: "${dataset.second}",
                        fill: false
                    }
                    """.trimIndent()
                }

                println("dataArrays: $dataArrays")

                // Check if xValues1 is already declared in the global scope
                val script = """
                    if (window.xValues1 === undefined) {
                        window.xValues1 = [${xValues1.map { "\"$it\"" }.joinToString(",")}];
                    }

                    new Chart("lineChart", {
                        type: "line",
                        data: {
                            labels: window.xValues1,
                            datasets: [
                                $dataArrays
                            ]
                        },
                        options: {
                            legend: { display: false },
                            scales: {
                                x: {
                                    grid: {
                                        display: false
                                    }
                                },
                                y: {
                                    grid: {
                                        display: true
                                    },
                                    beginAtZero: true
                                }
                            },
                            responsive: false,
                            maintainAspectRatio: true
                        }
                    });
                """.trimIndent()

                // Adding the script to the page
                val scriptElement = document.createElement("script")
                scriptElement.textContent = script
                scriptElement.setAttribute("id", "lineChartScript") // Set unique ID to avoid multiple appends
                val existingScript = document.getElementById("lineChartScript")
                if (existingScript == null) {
                    document.body?.appendChild(scriptElement)
                } else {
                    existingScript.replaceWith(scriptElement) // Replace the old script to avoid duplicate scripts
                }
            } else {
                console.error("Canvas element not found")
            }
        }
//    }
//    else {
//        Text("Loading chart data...")
//    }
}



