package org.example.zone360.pages.dashboard.infoLayout

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaChartSimple
import com.varabyte.kobweb.silk.components.icons.fa.FaClock
import com.varabyte.kobweb.silk.components.icons.fa.FaInfo
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.icons.fa.IconStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun InfoLayout(data: List<GetClubCoachesData>,
    breakpoint: Breakpoint){
    val sitePalette = ColorMode.current.toSitePalette()

    val validData = data.filter { it.totalKeywords != 0 }

    Row (modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween){
        println("")
        infoCard(

            "Total Sessions", "${data.sumOf { it.count }}", icon = {
                Image(Res.Images.footballicon)
            },
            infoTip = {},
            breakpoint = breakpoint

        )
        infoCard(
            "Minutes Recorded",
            data.sumOf { it.recordTime/1000 }.formatSessionMinutes(),
            icon = {
                FaClock(
                    modifier = Modifier.color(sitePalette.primary.rgb), size = IconSize.XL,
                    style = IconStyle.FILLED
                )
            },
            infoTip = {},
            breakpoint = breakpoint

        )
        infoCard(
            "Total Coaches", "${
                data.size
            }", icon = { Image(Res.Images.whistle) },
            infoTip = {},
            breakpoint = breakpoint
        )
        infoCard(
            "Average Keywords",
            "${if (validData.isNotEmpty()) (validData.sumOf { it.totalKeywords } / validData.size) else 0}",
            icon = {
                FaChartSimple(
                    modifier = Modifier.color(sitePalette.primary.rgb),
                    size = IconSize.XL
                )
            },
            infoTip = {},
            breakpoint = breakpoint
        )
    }
}
@Composable
fun infoCard(title: String,
             value: String,
             icon: @Composable () -> Unit,
             infoTip: @Composable () -> Unit,
             breakpoint: Breakpoint
){

    val sitePalette = ColorMode.current.toSitePalette()
    Box(modifier = Modifier
        .height(110.px)
        .width(if(breakpoint >= Breakpoint.LG) 24.percent else if(breakpoint >= Breakpoint.MD || window.screen.width in 580..770 ) 48.percent else 100.percent)
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .borderRadius(10.px)
        .margin(topBottom = 10.px)
        .padding(left= 20.px),
        contentAlignment = Alignment.CenterStart
    ){
        Row (
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
        ){
            Box (modifier = Modifier
                .width(60.px)
                .height(60.px)
                .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                .borderRadius(100.px)
                .margin(right = 10.px),
                contentAlignment = Alignment.Center
            ) {
                icon()
            }
            Column {
                Row (
                    verticalAlignment = Alignment.CenterVertically
                ){
                    SpanText(
                        "$title ",
                        modifier = Modifier.fontFamily(Constants.FONT_FAMILY).fontSize(15.px)
                    )
                    Box (modifier = Modifier
                        .width(18.px)
                        .height(18.px)
                        .backgroundColor(Colors.LightGray)
                        .borderRadius(100.px)
                        .margin(right = 10.px),
                        contentAlignment = Alignment.Center
                    ) {
                        FaInfo(modifier = Modifier.color(Colors.White).cursor(Cursor.Pointer), size = IconSize.XXS)
                    }
                } to
                        SpanText(value, modifier = Modifier.fontFamily(Constants.FONT_FAMILY).fontWeight(
                            FontWeight.Bold).margin(top = 6.px))
            }
        }
    }
}