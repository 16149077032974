package org.example.zone360.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.theme.Palette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button

@Composable
fun CommonButton(
    isSecondary: Boolean = false,
    text: String?= null,
    color: com.varabyte.kobweb.compose.ui.graphics.Color? = null,
    onCLick:  () -> Unit,
    isLoading: Boolean = false,
    width: CSSLengthOrPercentageNumericValue = 350.px,
    height: CSSLengthOrPercentageNumericValue = 44.px,
    byPassEmailVerification: Boolean = true,
    icon: (@Composable () -> Unit)? = null,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    Button(
        attrs = Modifier
            .width(width)
            .height(height)
            .margin(bottom = 3.px, top = 3.px)
            .backgroundColor(if (isSecondary) sitePalette.commonSecondaryButtonColor.rgb else sitePalette.primary.rgb)
            .color(if (isSecondary) sitePalette.textFieldTextColor.rgb else Palette.White.rgbLight)
            .border(color = sitePalette.primary.rgb)
            .borderRadius(r = 6.px)
            .fontWeight(FontWeight.Normal)
            .fontSize(13.px)
            .fontFamily(Constants.FONT_FAMILY)
            .cursor(Cursor.Pointer)
            .onClick {
                    println("button clicked")
                    onCLick()
            }
            .toAttrs()
    ) {
        if (isLoading) {
            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                ProgressIndicator()
            }
        } else {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                icon?.let {
                    it()
                    Box (modifier = Modifier.width(10.px)) // Add some space between icon and text
                }
                SpanText(text = text!!)
            }
        }
    }
}
//
@Composable
fun CommonButtonIcon(
    isSecondary: Boolean = false,
    onCLick: () -> Unit,
    icon: @Composable () -> Unit,
    isLoading: Boolean = false,
    width: CSSLengthNumericValue = 50.px,
    marginLeft: CSSLengthNumericValue = 0.px,
    marginRight: CSSLengthNumericValue = 0.px,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    Button(
        attrs = Modifier
            .width(width)
            .height(44.px)
            .margin(bottom = 3.px, left = marginLeft, right = marginRight)
            .backgroundColor(if (isSecondary) sitePalette.commonSecondaryButtonColor.rgb else Palette.White.rgbLight)
            .color(if (isSecondary) sitePalette.textFieldTextColor.rgb else Palette.White.rgbLight)
            .border(color = sitePalette.primary.rgb)
            .borderRadius(r = 6.px)
            .fontWeight(FontWeight.Normal)
            .fontSize(13.px)
            .fontFamily(Constants.FONT_FAMILY)
            .cursor(Cursor.Pointer)
            .onClick {
                if (!isLoading) {
                    println("button clicked")
                    onCLick()
                }


            }
            .toAttrs()
    ) {
        if (isLoading) Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            ProgressIndicator()
        } else
            icon()
    }
}