package org.example.zone360.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.CSSLengthOrPercentageValue
import org.jetbrains.compose.web.css.px

@Composable
fun CustomSpacer(
    width : CSSLengthOrPercentageValue = 0.px,
    height : CSSLengthOrPercentageValue = 0.px
){
    Box (modifier = Modifier.height(height).width(width)){  }
}