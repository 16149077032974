package org.example.zone360.components


import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onFocusIn
import com.varabyte.kobweb.compose.ui.modifiers.onFocusOut
import com.varabyte.kobweb.compose.ui.modifiers.onKeyDown
import com.varabyte.kobweb.compose.ui.modifiers.onKeyUp
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.example.zone360.componentss.FieldTitleTextNormal
import org.example.zone360.style.LoginInputStyleDefault
import org.example.zone360.style.LoginInputStyleError
import org.example.zone360.style.LoginInputStyleFocus
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants.FONT_FAMILY
import org.example.zone360.utils.isValidEmail
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSLengthOrPercentageValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLInputElement

@Composable
fun InputField(
    errorText: String? = "",
    inputType: InputType<String>,
    placeHolder: String,
    id: String = "",
    isError: Boolean = false,
    marginBottom: CSSLengthNumericValue? = null,
    width: CSSLengthOrPercentageValue  = 350.px,
    fixHeight: Boolean = false,
    maxLength: String = "524288",
    onValueChange: ((String) -> Unit)? = null,
    disabled: Boolean = false,


) {

    var modifier by remember { mutableStateOf(LoginInputStyleDefault) }
    var text by remember { mutableStateOf("") }
    var textError by remember { mutableStateOf("") }

    val sitePalette = ColorMode.current.toSitePalette()

    if (isError) {

        println("default: $isError")
        text = (document.getElementById(id) as HTMLInputElement).value
        if (text.isBlank()) {
            modifier = LoginInputStyleError.border(
                width = 2.px,
                style = LineStyle.Solid,
                color = sitePalette.textErrorColor.rgb
            )
            textError = errorText!!
        } else {
            if (inputType == InputType.Email) {
                if (!isValidEmail(email = text)) {
                    modifier = LoginInputStyleError.border(
                        width = 2.px,
                        style = LineStyle.Solid,
                        color = sitePalette.textErrorColor.rgb
                    )
                    textError = "Please enter a valid email (e.g. name@domain.com)."
                } else {
                    modifier = LoginInputStyleDefault
                    textError = ""
                }
            } else {
                modifier = LoginInputStyleDefault
                textError = ""
            }
        }

    }


    Column(modifier = if (fixHeight) Modifier.height(80.px) else Modifier) {
        Input(type = inputType,
            attrs = modifier.id(id).margin(bottom = marginBottom ?: 20.px)
                .fillMaxWidth(width!!).height(45.px).padding(leftRight = 20.px)
                .backgroundColor(if (disabled) sitePalette.textFieldBorderColorDisabled.rgb else sitePalette.modalBackgroundColor.rgb)
                .color(if (disabled) sitePalette.textFieldSupportiveTextColor.rgb else sitePalette.textFieldTextColor.rgb)
                .fontFamily(FONT_FAMILY).fontSize(14.px).borderRadius(r = 5.px).outline(
                    width = 0.px, style = LineStyle.None, color = Colors.Transparent
                ).onFocusOut {
                    println("focus out: $errorText")
                    text = (document.getElementById(id) as HTMLInputElement).value
                    if (text.isBlank()) {
                        modifier = LoginInputStyleError.border(
                            width = 2.px,
                            style = LineStyle.Solid,
                            color = sitePalette.textErrorColor.rgb
                        )
                        textError = errorText!!
                    } else {
                        if (inputType == InputType.Email) {
                            if (!isValidEmail(email = text)) {
                                modifier = LoginInputStyleError
                                textError = "Please enter a valid email (e.g. name@domain.com)."
                            } else {
                                modifier = LoginInputStyleDefault
                                textError = ""
                            }
                        } else {
                            modifier = LoginInputStyleDefault
                            textError = ""
                        }


                    }


                }.onFocusIn {


                    modifier =
                        LoginInputStyleFocus


                }.onKeyUp {
                    text = (document.getElementById(id) as HTMLInputElement).value

                    if (text.isBlank()) {
                        modifier = LoginInputStyleError.border(
                            width = 2.px,
                            style = LineStyle.Solid,
                            color = sitePalette.textErrorColor.rgb
                        )
                        textError = errorText!!
                    } else {
                        if (inputType == InputType.Email) {
                            if (!isValidEmail(email = text)) {
                                modifier = LoginInputStyleError.border(
                                    width = 2.px,
                                    style = LineStyle.Solid,
                                    color = sitePalette.textErrorColor.rgb
                                )
                                textError = "Please enter a valid email (e.g. name@domain.com)."
                            } else {
                                modifier = LoginInputStyleFocus
                                textError = ""
                            }
                        } else {
                            modifier = LoginInputStyleFocus
                            textError = ""
                        }


                    }

                }
                .toAttrs {
                    attr("placeholder", placeHolder)
                    attr("maxlength", maxLength)
                })
        FieldTitleTextNormal(
            marginTop = 3.px,
            fontSize = 11.px,
            text = textError,
            color = sitePalette.textErrorColor.rgb, onClick = {}
        )
    }
}

@Composable
fun OptionalInputField(
    inputType: InputType<String>,
    placeHolder: String,
    id: String = "",
    marginBottom: CSSLengthNumericValue? = null,
    fontSize: CSSLengthNumericValue = 14.px,
    disabled: Boolean = false,
) {
    var modifier by remember { mutableStateOf(LoginInputStyleDefault) }
    val sitePalette = ColorMode.current.toSitePalette()

    modifier =
        if (disabled) modifier.border(width = 0.px) else (if (modifier == LoginInputStyleFocus) LoginInputStyleFocus else LoginInputStyleDefault)


    Input(type = inputType,
        attrs = modifier.id(id).margin(bottom = marginBottom ?: 20.px)
            .fillMaxWidth().height(45.px).padding(leftRight = 20.px)
            .backgroundColor(if (disabled) sitePalette.textFieldBorderColorDisabled.rgb else sitePalette.modalBackgroundColor.rgb)
            .color(if (disabled) sitePalette.textFieldSupportiveTextColor.rgb else sitePalette.textFieldTextColor.rgb)
            .fontFamily(FONT_FAMILY).fontSize(fontSize).borderRadius(r = 5.px).outline(
                width = 0.px, style = LineStyle.None, color = Colors.Transparent
            ).onFocusOut {
                modifier = LoginInputStyleDefault

            }.onFocusIn {
                modifier =
                    LoginInputStyleFocus
            }.styleModifier {
                if (disabled) {
                    property("pointer-events", "none")
                }
            }
            .toAttrs {
                attr("placeholder", placeHolder)
            })
}


@Composable
fun TableInputField(
    placeHolder: String = "0.00",
    id: String = "",
    min: String = "0",
    step: String = "0.1",
    marginBottom: CSSLengthNumericValue? = null,
    disabled: Boolean = false,
    withRadius: Boolean = false,
    withoutDecimal: Boolean = false,
    onChange: () -> Unit
) {
    var modifier by remember { mutableStateOf(LoginInputStyleDefault) }

    val sitePalette = ColorMode.current.toSitePalette()

    modifier =
        if (disabled) modifier.border(
            width = 1.px,
            color = sitePalette.textFieldBorderColor.rgb,
            style = LineStyle.Solid
        ) else (if (modifier == LoginInputStyleFocus) LoginInputStyleFocus else LoginInputStyleDefault)


    Input(type = InputType.Text,
        attrs = modifier.id(id).margin(bottom = marginBottom ?: 20.px)
            .fillMaxWidth().height(45.px).padding(leftRight = 20.px)
            .backgroundColor(if (disabled) sitePalette.textFieldBorderColorDisabled.rgb else sitePalette.modalBackgroundColor.rgb)
            .color(if (disabled) sitePalette.textFieldSupportiveTextColor.rgb else sitePalette.textFieldTextColor.rgb)
            .fontFamily(FONT_FAMILY).fontSize(14.px)
            .borderRadius(r = if (withRadius) 5.px else 0.px).outline(
                width = 0.px, style = LineStyle.None, color = Colors.Transparent
            ).onFocusOut {
                modifier = LoginInputStyleDefault


            }.onFocusIn {
                modifier =
                    LoginInputStyleFocus
            }.onKeyDown {
                if (it.key != "Backspace") {

                    val regex = if (withoutDecimal) "[\\d]".toRegex() else "[\\d.]".toRegex()
                    val value =
                        (document.getElementById(id) as HTMLInputElement).value

                    if (regex.matches(it.key)) {
                        if (it.key == "." && value.contains(".")) {
                            it.preventDefault()
                        }
                    } else {
                        it.preventDefault()
                    }
                }

            }.onKeyUp {
                onChange()
            }.styleModifier {
                if (disabled) {
                    property("pointer-events", "none")
                }
                property("inputmode", "numeric")

            }
            .toAttrs {
                attr("placeholder", placeHolder)
                attr("min", min)
                attr("step", step)
            })
}