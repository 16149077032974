@file:Suppress("DEPRECATION")

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.coroutines.launch
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.components.formatSessionDate
import org.example.zone360.components.formatSessionHours
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.models.GetSessionSummaryData
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px


@Composable
fun RecommendationPopup(
    onDialogDismiss: (Long) -> Unit,
    breakpoint: Breakpoint,
    modalNumber: Long,
    data: GetClubCoachesData,
    language: String

) {
    val scope = rememberCoroutineScope()
    val sitePalette = ColorMode.current.toSitePalette()
    val sessionSummary = remember { mutableStateListOf<GetSessionSummaryData>() }
    var isLoading by remember { mutableStateOf(true) } // Loading state



    LaunchedEffect(Unit) {
        scope.launch {
            try {
                val sessionUser = getSessionSummary(
                    userID = data.userId,
                    domainType = "soccer",
                    language = language,
                    getAll = true
                )
                sessionSummary.clear()
                sessionSummary.addAll(sessionUser.data)
            } catch (e: Exception) {
                println("Error fetching session summaries: $e")
            } finally {
                isLoading = false
            }
        }
    }

    Column(modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally) {
        Box(
            modifier = Modifier
                .width(if (breakpoint > com.varabyte.kobweb.silk.style.breakpoint.Breakpoint.LG) 50.percent else 75.percent)
                .height(529.px)
                .position(Position.Fixed)
                .id("recommendation-modal-$modalNumber")
                .zIndex(999999)
                .top(50.percent)
                .left(50.percent)
                .transform { translate((-50).percent, (-50).percent) },
            contentAlignment = Alignment.TopCenter,
        ) {
            Box(
                modifier = Modifier
                    .width(90.percent)
                    .height(90.percent)
                    .onClick { }
            )
            Box(
                modifier = Modifier
                    .padding(all = 24.px)
                    .width(90.percent)
                    .margin(top = 10.px)
                    .height(90.percent)
                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                    .border(
                        width = 1.px,
                        color = sitePalette.textFieldBorderColor.rgb,
                        style = LineStyle.Solid
                    )
                    .borderRadius(16.px)
            ) {

                Column(modifier = Modifier.fillMaxSize()) {

                    Row(
                        horizontalArrangement = Arrangement.SpaceBetween,
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(bottom = 20.px),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Column {
                            FieldTitleTextNormalSimple(
                                "Recommedation",
                                fontSize = 15.px,
                                fontWeight = 600,
                                onClick = {},
                                color = Colors.Black
                            )
                        }

                        FaXmark(
                            modifier = Modifier
                                .color(sitePalette.commonButtonSupportiveTextColor.rgb)
                                .cursor(Cursor.Pointer)
                                .onClick { onDialogDismiss(modalNumber) },
                            size = IconSize.LG
                        )
                    }

                    Row(verticalAlignment = Alignment.CenterVertically) {
                        if (data.imageUrl.isNotEmpty()) {
                            Image(
                                src = "${Res.Images.coachImageUrl}${data.imageUrl}",
                                modifier = Modifier.size(40.px).margin(right = 8.px)
                                    .borderRadius(100.px).textAlign(TextAlign.Start)
                            )
                        } else {
                            Image(
                                src = Res.Images.userIcon,
                                modifier = Modifier.size(40.px).margin(right = 7.px)
                                    .borderRadius(100.px)
                            )
                        }

                        FieldTitleTextNormalSimple(
                            "${data.firstName} ${data.lastName}",
                            fontSize = 24.px,
                            fontWeight = 600,
                            onClick = {},
                            color = Colors.Black
                        )

                    }
                    if (isLoading) {
                        Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.Center
                        ) {
                            ProgressIndicator(
                                size = 40.px,
                            )
                        }
                    } else {
                        Column(
                            modifier = Modifier.margin(topBottom = 7.px)
                                .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                        ) {
                            if (sessionSummary.isEmpty()){
                                FieldTitleTextNormalSimple(
                                    text = "No summaries found!",
                                    width = 520.px,
                                    fontSize = 13.px,
                                    textAlign = TextAlign.Center
                                )
                            }
                            sessionSummary.forEach { summaryData ->
                                if (summaryData.summary.isNotEmpty()) {
                                    val summary = summaryData.sessionId
                                    val parts = summary.split(".")
                                    val dataParts = parts.first().split("_")
                                    val name = dataParts.first()
                                    val timeStamp = dataParts.last()
                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                            .margin(topBottom = 6.px, leftRight = 0.px),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {
                                        FieldTitleTextNormalSimple(
                                            text = if (summaryData.sessionType == "player assessment") {
                                                "${summaryData.playerName}'s assessment"
                                            } else {
                                                name
                                            },
                                            fontSize = 20.px,
                                            fontWeight = 500,
                                        )

                                        Row {

                                            FieldTitleTextNormalSimple(
                                                text = timeStamp.formatSessionHours(),
                                                fontSize = 14.px,
                                            )
                                            FieldTitleTextNormalSimple(
                                                text = " ${timeStamp.formatSessionDate()}",
                                                fontSize = 14.px,
                                            )
                                        }
                                    }

                                    FieldTitleTextNormalSimple(
                                        text = summaryData.summary.replaceFirstChar {
                                            if (it.isLowerCase()) it.titlecase() else it.toString()
                                        },
                                        fontSize = 13.px,
                                    )
                                    HorizontalDivider(modifier = Modifier.fillMaxWidth())

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}