package org.example.zone360.theme

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.rgb
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.rgba

///
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color

enum class Palette(
    val hexLight: String,
    val rgbLight: Color.Rgb,
    val hexDark: String,
    val rgbDark: Color.Rgb,
) {
    Primary(
        hexLight = "#FB6F2A",
        rgbLight = rgb(r =251, g = 111, b = 42),
        hexDark = "#35D593",
        rgbDark = rgb(r = 53, g = 213, b = 147),

        ),
    Secondary(
        hexLight = "#C9F9CD",
        rgbLight = rgba(201, 249, 205, 1f),
        hexDark = "#35D593",
        rgbDark = rgb(r = 53, g = 213, b = 147),

        ),
    BodyBackground(
        hexLight = "#F4F4F4",
        rgbLight = rgb(r = 244, g = 244, b = 244),
        hexDark = "#0F0819",
        rgbDark = rgb(15, 8, 25),
    ),

    TableHeader(
        hexLight = "#E0E0E0",
        rgbLight = rgb(r = 224, g = 224, b = 224),
        hexDark = "#0F0819",
        rgbDark = rgb(15, 8, 25),
    ),
    SearchbarPlaceholder(
        hexLight = "#A4A4A4",
        rgbLight = rgb(r = 164, g = 164, b = 164),
        hexDark = "#F7F7FA",
        rgbDark = rgb(247, 247, 250)
    ),

    Error(
        hexLight = "#B7182B",
        rgbLight = rgb(183, 24, 43),
        hexDark = "#E24A61",
        rgbDark = rgb(226, 74, 97)
    ),
    Red(
        hexLight = "#D85B6A",
        rgbLight = rgba(216, 91, 106, 1f),
        hexDark = "#E24A61",
        rgbDark = rgb(226, 74, 97)
    ),
    Success(
        hexLight = "#23A971",
        rgbLight = rgba(35, 169, 113, 1f),
        hexDark = "#219B65",
        rgbDark = rgba(33, 155, 101, 1f)
    ),
    EditTextBorder(
        hexLight = "#D4D4D4",
        rgbLight = rgb(212, 212, 212),
        hexDark = "#B9BCC2",
        rgbDark = rgb(185, 188, 194)
    ),
    EditTextSupportText(
        hexLight = "#424242",
        rgbLight = rgb(66, 66, 66),
        hexDark = "#94979C",
        rgbDark = rgb(148, 151, 156)
    ),
    ParrotGreen(
        hexLight = "#DAFD5D",
        rgbLight = rgb(218, 253, 93),
        hexDark = "#DAFD5D",
        rgbDark = rgb(218, 253, 93),
    ),
    ButtonTextSupportive(
        hexLight = "#787878",
        rgbLight = rgb(120, 120, 120),
        hexDark = "#F7F7FA",
        rgbDark = rgb(247, 247, 250)
    ),
    Disabled(
        hexLight = "#F4F4F4",
        rgbLight = rgba(244, 244, 244, 1f),
        hexDark = "#252132",
        rgbDark = rgba(37, 33, 50, 1f)
    ),
    Black(
        hexLight = "#000000",
        rgbLight = Colors.Black,
        hexDark = "#000000",
        rgbDark = Colors.Black,
    ),
    White(
        hexLight = "#FFFFFF",
        rgbLight = Colors.White,
        hexDark = "#FFFFFF",
        rgbDark = Colors.White,
    ),
    TextWhite(
        hexLight = "#FFFFFF",
        rgbLight = Colors.White,
        hexDark = "#FFFFFF",
        rgbDark = Colors.White,
    ),
    Modal(
        hexLight = "#FFFFFF",
        rgbLight = Colors.White,
        hexDark = "#181222",
        rgbDark = rgb(24, 18, 34),
    ),
    FadedBlack(
        hexLight = "#000000",
        rgbLight = rgba(0, 0, 0, 0.5f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
   TechnicalCategoryBackground(
        hexLight = "#000000",
        rgbLight = rgba(0, 169, 157, 0.06f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    FitnessCategoryBackground(
        hexLight = "#000000",
        rgbLight = rgba(255, 95, 95, 0.06f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    TacticalCategoryBackground(
        hexLight = "#000000",
        rgbLight = rgba(167, 57, 213, 0.06f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    MentalCategoryBackground(
        hexLight = "#000000",
        rgbLight = rgba(0, 130, 255, 0.06f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    GoalKeeperCategoryBackground(
        hexLight = "#000000",
        rgbLight = rgba(3, 110, 167, 0.06f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
   TechnicalCategoryBorder(
        hexLight = "#00A99D",
        rgbLight = rgba(0, 169, 157, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    FitnessCategoryBorder(
        hexLight = "#FF5F5F",
        rgbLight = rgba(255, 95, 95, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    TacticalCategoryBorder(
        hexLight = "#A739D5",
        rgbLight = rgba(167, 57, 213, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    MentalCategoryBorder(
        hexLight = "#0082FF",
        rgbLight = rgba(0, 130, 255, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    GoalKeeperCategoryBorder(
        hexLight = "#036EA7",
        rgbLight = rgba(3, 110, 167, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    ClubPerformanceText(
        hexLight = "#000000",
        rgbLight = rgba(136, 136, 136, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    CoachesTileRow(
        hexLight = "#000000",
        rgbLight = rgba(249, 252, 255, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    ViewButtonBackground(
        hexLight = "#E9F4FF",
        rgbLight = rgba(233, 244, 255, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    ),
    ViewText(
        hexLight = "#0082FF",
        rgbLight = rgba(0, 130, 255, 1f),
        hexDark = "#000000",
        rgbDark = rgba(0, 0, 0, 0.5f),
    )



}


class SitePalette(
    // top level colors
    val primary: ColorVariation,
    val bodyBackground: ColorVariation,
    val bodyBackgroundSecondary: ColorVariation,

    // Button Colors
    val specialButtonBorderColor: ColorVariation,
    val commonButtonBorderColor: ColorVariation,
    val commonSecondaryButtonColor: ColorVariation,
    val commonButtonSupportiveTextColor: ColorVariation,

    // Header colors
    val headerBackgroundColor: ColorVariation,
    val tablehHeaderBackgroundColor: ColorVariation,


    // Text Field Colors
    val textFieldTextColor: ColorVariation,
    val textFieldSupportiveTextColor: ColorVariation,
    val textFieldBorderColor: ColorVariation,
    val textFieldBorderColorDisabled: ColorVariation,
    val searchbarBorderColorPlaceholder: ColorVariation,

    // Modal Colors
    val modalBackgroundColor: ColorVariation,


    // Other Text Colors
    val textErrorColor: ColorVariation,
    val textRedColor: ColorVariation,
    val textGreenColor: ColorVariation,
    val clubPerformanceTextColor: ColorVariation,
    val fadedBlack: ColorVariation,
    val viewButtonBackground: ColorVariation,
    val viewText: ColorVariation,

//    Keyword Colors
    val technicalCategoryBackground: ColorVariation,
    val fitnessCategoryBackground: ColorVariation,
    val tacticalCategoryBackground: ColorVariation,
    val mentalCategoryBackground: ColorVariation,
    val goalKeeperCategoryBackground: ColorVariation,
    val technicalCategoryBorder: ColorVariation,
    val fitnessCategoryBorder: ColorVariation,
    val tacticalCategoryBorder: ColorVariation,
    val mentalCategoryBorder: ColorVariation,
    val goalKeeperCategoryBorder: ColorVariation,
//
    val coachesTileRow: ColorVariation,

    ) {
    class ColorVariation(
        val hex: String,
        val rgb: Color.Rgb
    )
}

object SitePalettes {
    val light = SitePalette(
        // top level colors
        primary = SitePalette.ColorVariation(
            hex = Palette.Primary.hexLight,
            rgb = Palette.Primary.rgbLight
        ),
        bodyBackground = SitePalette.ColorVariation(
            hex = Palette.BodyBackground.hexLight,
            rgb = Palette.BodyBackground.rgbLight

        ),
        bodyBackgroundSecondary = SitePalette.ColorVariation(
            hex = Palette.White.hexLight,
            rgb = Palette.White.rgbLight

        ),

        // Button colors
        specialButtonBorderColor = SitePalette.ColorVariation(
            hex = Palette.ParrotGreen.hexLight,
            rgb = Palette.ParrotGreen.rgbLight

        ),
        commonButtonBorderColor = SitePalette.ColorVariation(
            hex = Palette.Primary.hexLight,
            rgb = Palette.Primary.rgbLight

        ),
        commonSecondaryButtonColor = SitePalette.ColorVariation(
            hex = Palette.Secondary.hexLight,
            rgb = Palette.Secondary.rgbLight

        ),
        commonButtonSupportiveTextColor = SitePalette.ColorVariation(
            hex = Palette.ButtonTextSupportive.hexLight,
            rgb = Palette.ButtonTextSupportive.rgbLight

        ),

        // Header colors

        headerBackgroundColor = SitePalette.ColorVariation(
            hex = Palette.Primary.hexLight,
            rgb = Palette.Primary.rgbLight

        ),

        tablehHeaderBackgroundColor = SitePalette.ColorVariation(
            hex = Palette.TableHeader.hexLight,
            rgb = Palette.TableHeader.rgbLight

        ),

        // Text Field colors
        textFieldTextColor = SitePalette.ColorVariation(
            hex = Palette.Black.hexLight,
            rgb = Palette.Black.rgbLight

        ),
        textFieldSupportiveTextColor = SitePalette.ColorVariation(
            hex = Palette.EditTextSupportText.hexLight,
            rgb = Palette.EditTextSupportText.rgbLight

        ),
        textFieldBorderColorDisabled = SitePalette.ColorVariation(
            hex = Palette.Disabled.hexLight,
            rgb = Palette.Disabled.rgbLight

        ),
        textFieldBorderColor = SitePalette.ColorVariation(
            hex = Palette.EditTextBorder.hexLight,
            rgb = Palette.EditTextBorder.rgbLight

        ),
        searchbarBorderColorPlaceholder = SitePalette.ColorVariation(
            hex = Palette.SearchbarPlaceholder.hexLight,
            rgb = Palette.SearchbarPlaceholder.rgbLight

        ),

        // Modal background color
        modalBackgroundColor = SitePalette.ColorVariation(
            hex = Palette.Modal.hexLight,
            rgb = Palette.Modal.rgbLight

        ),

        // Other text colors
        textErrorColor = SitePalette.ColorVariation(
            hex = Palette.Error.hexLight,
            rgb = Palette.Error.rgbLight

        ),

        textRedColor = SitePalette.ColorVariation(
            hex = Palette.Red.hexLight,
            rgb = Palette.Red.rgbLight

        ),
        textGreenColor = SitePalette.ColorVariation(
            hex = Palette.Success.hexLight,
            rgb = Palette.Success.rgbLight

        ),
        fadedBlack = SitePalette.ColorVariation(
            hex = Palette.FadedBlack.hexLight,
            rgb = Palette.FadedBlack.rgbLight
        ),
       technicalCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.TechnicalCategoryBackground.hexLight,
            rgb = Palette.TechnicalCategoryBackground.rgbLight
        ),
        fitnessCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.FitnessCategoryBackground.hexLight,
            rgb = Palette.FitnessCategoryBackground.rgbLight
        ),
        tacticalCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.TacticalCategoryBackground.hexLight,
            rgb = Palette.TacticalCategoryBackground.rgbLight
        ),
        mentalCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.MentalCategoryBackground.hexLight,
            rgb = Palette.MentalCategoryBackground.rgbLight
        ),
        goalKeeperCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.GoalKeeperCategoryBackground.hexLight,
            rgb = Palette.GoalKeeperCategoryBackground.rgbLight
        ),
       technicalCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.TechnicalCategoryBorder.hexLight,
            rgb = Palette.TechnicalCategoryBorder.rgbLight
        ),
        fitnessCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.FitnessCategoryBorder.hexLight,
            rgb = Palette.FitnessCategoryBorder.rgbLight
        ),
        tacticalCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.TacticalCategoryBorder.hexLight,
            rgb = Palette.TacticalCategoryBorder.rgbLight
        ),
        mentalCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.MentalCategoryBorder.hexLight,
            rgb = Palette.MentalCategoryBorder.rgbLight
        ),
        goalKeeperCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.GoalKeeperCategoryBorder.hexLight,
            rgb = Palette.GoalKeeperCategoryBorder.rgbLight
        ),
        clubPerformanceTextColor = SitePalette.ColorVariation(
            hex = Palette.ClubPerformanceText.hexLight,
            rgb = Palette.ClubPerformanceText.rgbLight
        ),
        coachesTileRow = SitePalette.ColorVariation(
            hex = Palette.CoachesTileRow.hexLight,
            rgb = Palette.CoachesTileRow.rgbLight
        ),
        viewButtonBackground = SitePalette.ColorVariation(
            hex = Palette.ViewButtonBackground.hexLight,
            rgb = Palette.ViewButtonBackground.rgbLight
        ),
        viewText = SitePalette.ColorVariation(
            hex = Palette.ViewText.hexLight,
            rgb = Palette.ViewText.rgbLight
        )
    )

    val dark = SitePalette(
        // top level colors
        primary = SitePalette.ColorVariation(
            hex = Palette.Primary.hexDark,
            rgb = Palette.Primary.rgbDark
        ),
        bodyBackground = SitePalette.ColorVariation(
            hex = Palette.BodyBackground.hexDark,
            rgb = Palette.BodyBackground.rgbDark

        ),
        bodyBackgroundSecondary = SitePalette.ColorVariation(
            hex = Palette.BodyBackground.hexDark,
            rgb = Palette.BodyBackground.rgbDark

        ),

        // Button colors
        specialButtonBorderColor = SitePalette.ColorVariation(
            hex = Palette.Primary.hexDark,
            rgb = Palette.Primary.rgbDark

        ),
        commonButtonBorderColor = SitePalette.ColorVariation(
            hex = Palette.Primary.hexDark,
            rgb = Palette.Primary.rgbDark

        ),
        commonSecondaryButtonColor = SitePalette.ColorVariation(
            hex = Palette.Secondary.hexDark,
            rgb = Palette.Secondary.rgbDark

        ),
        commonButtonSupportiveTextColor = SitePalette.ColorVariation(
            hex = Palette.ButtonTextSupportive.hexDark,
            rgb = Palette.ButtonTextSupportive.rgbDark

        ),

        // Header colors

        headerBackgroundColor = SitePalette.ColorVariation(
            hex = Palette.BodyBackground.hexDark,
            rgb = Palette.BodyBackground.rgbDark

        ),

        tablehHeaderBackgroundColor = SitePalette.ColorVariation(
            hex = Palette.TableHeader.hexDark,
            rgb = Palette.TableHeader.rgbDark

        ),


        // Text Field colors
        textFieldTextColor = SitePalette.ColorVariation(
            hex = Palette.White.hexDark,
            rgb = Palette.White.rgbDark

        ),
        textFieldSupportiveTextColor = SitePalette.ColorVariation(
            hex = Palette.EditTextSupportText.hexDark,
            rgb = Palette.EditTextSupportText.rgbDark

        ),
        textFieldBorderColorDisabled = SitePalette.ColorVariation(
            hex = Palette.Disabled.hexDark,
            rgb = Palette.Disabled.rgbDark

        ),
        textFieldBorderColor = SitePalette.ColorVariation(
            hex = Palette.EditTextBorder.hexDark,
            rgb = Palette.EditTextBorder.rgbDark

        ),
        searchbarBorderColorPlaceholder = SitePalette.ColorVariation(
            hex = Palette.SearchbarPlaceholder.hexDark,
            rgb = Palette.SearchbarPlaceholder.rgbDark

        ),

        // Modal background color
        modalBackgroundColor = SitePalette.ColorVariation(
            hex = Palette.Modal.hexDark,
            rgb = Palette.Modal.rgbDark

        ),

        // Other text colors
        textErrorColor = SitePalette.ColorVariation(
            hex = Palette.Error.hexDark,
            rgb = Palette.Error.rgbDark

        ),

        textRedColor = SitePalette.ColorVariation(
            hex = Palette.Red.hexDark,
            rgb = Palette.Red.rgbDark

        ),
        textGreenColor = SitePalette.ColorVariation(
            hex = Palette.Success.hexDark,
            rgb = Palette.Success.rgbDark

        ),
        fadedBlack = SitePalette.ColorVariation(
            hex = Palette.FadedBlack.hexDark,
            rgb = Palette.FadedBlack.rgbDark
        ),
       technicalCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.TechnicalCategoryBackground.hexDark,
            rgb = Palette.TechnicalCategoryBackground.rgbDark
        ),
        fitnessCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.FitnessCategoryBackground.hexDark,
            rgb = Palette.FitnessCategoryBackground.rgbDark
        ),
        tacticalCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.TacticalCategoryBackground.hexDark,
            rgb = Palette.TacticalCategoryBackground.rgbDark
        ),
        mentalCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.MentalCategoryBackground.hexDark,
            rgb = Palette.MentalCategoryBackground.rgbDark
        ),
        goalKeeperCategoryBackground = SitePalette.ColorVariation(
            hex = Palette.GoalKeeperCategoryBackground.hexDark,
            rgb = Palette.GoalKeeperCategoryBackground.rgbDark
        ),
       technicalCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.TechnicalCategoryBackground.hexDark,
            rgb = Palette.TechnicalCategoryBorder.rgbDark
        ),
        fitnessCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.FitnessCategoryBorder.hexDark,
            rgb = Palette.FitnessCategoryBorder.rgbDark
        ),
        tacticalCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.TacticalCategoryBorder.hexDark,
            rgb = Palette.TacticalCategoryBorder.rgbDark
        ),
        mentalCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.MentalCategoryBorder.hexDark,
            rgb = Palette.MentalCategoryBorder.rgbDark
        ),
        goalKeeperCategoryBorder = SitePalette.ColorVariation(
            hex = Palette.GoalKeeperCategoryBorder.hexDark,
            rgb = Palette.GoalKeeperCategoryBorder.rgbDark
        ),
        clubPerformanceTextColor = SitePalette.ColorVariation(
            hex = Palette.ClubPerformanceText.hexDark,
            rgb = Palette.ClubPerformanceText.rgbDark
        ),
        coachesTileRow = SitePalette.ColorVariation(
            hex = Palette.CoachesTileRow.hexDark,
            rgb = Palette.CoachesTileRow.rgbDark
        ),
        viewButtonBackground = SitePalette.ColorVariation(
            hex = Palette.ViewButtonBackground.hexDark,
            rgb = Palette.ViewButtonBackground.rgbDark
        ),
        viewText = SitePalette.ColorVariation(
            hex = Palette.ViewText.hexDark,
            rgb = Palette.ViewText.rgbDark
        )
       )
}

fun ColorMode.toSitePalette(): SitePalette {
    return when (this) {
        ColorMode.LIGHT -> SitePalettes.light
        ColorMode.DARK -> SitePalettes.dark
    }
}

@InitSilk
fun initTheme(ctx: InitSilkContext) {
    ctx.theme.palettes.light.background = Palette.BodyBackground.rgbLight
    ctx.theme.palettes.light.color = Colors.Black
    ctx.theme.palettes.dark.background = Palette.BodyBackground.rgbDark
    ctx.theme.palettes.dark.color = Colors.White
}