package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SessionResponse(
    val data: List<SessionData>,
)
@Serializable

data class SessionData(
    @SerialName("_id")
    val id: String="",
    @SerialName("_rev")
    val rev: String="",
    val audioPath: String="",
    val coachName: String="",
    val domainType: String="",
    val language: String="",
    val organization: String="",
    val playerName: String="",
    val recordTime: String="",
    val sessionData: String = "",
    @SerialName("sessionID")
    val sessionId: String="",
    val sessionType: String="",
    val status: String="",
    val statusUrl: String="",
    val summaryRevision: Int =0,
    val text: String="",
    val type: String="",
    @SerialName("userID")
    val userId: String="",
    val wordCount: Int=0,
)